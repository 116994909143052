import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy, Inject } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, delay, filter } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AuthService } from '@core/auth/services/auth.service';
import { UserData } from '@core/models/user.model';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmComponent } from '@tools/components/modal-confirm/modal-confirm.component';
import { FullscreenService } from './fullscreen.service';
import { MenuItem } from 'primeng/api';

@UntilDestroy()

@Component({
  selector: 'app-general-layout',
  templateUrl: './general-layout.component.html',
  styleUrls: ['./general-layout.component.scss']
})

export class GeneralLayoutComponent implements OnInit, AfterViewInit {
  screenWidth: number;
  screenHeight: number;

  @ViewChild(MatSidenav)
  public sidenav!: MatSidenav;
  public user: UserData;
  public menu: any[] = [];
  public isMovil: boolean = false;
  public showNavBar: boolean = false;
  public showNavPanel: boolean = false;
  public isHovering: boolean = false;
  public totalNotifications = 0;
  public isFullscreen: boolean = false;
  public showSearch: boolean = false;
  public items: MenuItem[] | undefined;
  private fullscreenSubscription: Subscription;
  private _subcriptions: Record<string, Subscription> = {};

  constructor(private observer: BreakpointObserver,
    private router: Router,
    private authSrv: AuthService,
    public dialog: MatDialog,
    private fullscreenService: FullscreenService
  ) {
    // this.menu = _storage.getMenu();
  }

  ngOnInit(): void {
    this.checkUser();
    this.checkNotifications();

    /* this.fullscreenService.fullscreenChange.subscribe(() => {
      this.isFullscreen = this.fullscreenService.fullscreenElement !== null;
    }); */

    window.document.addEventListener('onfullscreenchange', () => {
      this.isFullscreen = document.fullscreenElement !== null;
    });

    window.document.addEventListener('resize', () => {
      this.showNavPanel = false;
    });

    this.isFullscreen = this.fullscreenService.isFullscreen();
    this.fullscreenSubscription = this.fullscreenService.onFullscreenChange()
      .subscribe(() => {
        this.isFullscreen = this.fullscreenService.isFullscreen();
      });

    const resizeObserver = new ResizeObserver((entries) => {
      this.showNavPanel = false;
      this.showNavBar = false;
      for (const entry of entries) {
        this.screenWidth = entry.contentRect.width;
        this.screenHeight = entry.contentRect.height;
        if (this.screenWidth >= 768) {
          this.isMovil = false;
        } else {
          this.isMovil = true;
        }
      }
    });

    // Observe the window element for resize events
    resizeObserver.observe(document.documentElement);
  }

  ngOnDestroy(): void {
    Object.values(this._subcriptions).map(value => value.unsubscribe());

    if (this.fullscreenSubscription) {
      this.fullscreenSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.observer
      .observe(['(max-width: 800px)'])
      .pipe(delay(1), untilDestroyed(this))
      .subscribe((res) => {
        if (res.matches) {
          //this.sidenav.mode = 'over';
          //this.sidenav.close();
        } else {
          //this.sidenav.mode = 'side';
          //this.sidenav.open();
        }
      });

    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((e) => e instanceof NavigationEnd)
      )
      .subscribe(() => {
        if (this.sidenav?.mode === 'over') {
          this.sidenav.close();
        }
      });
  }

  logout(): void {
    this.dialog.open(ModalConfirmComponent, {
      data: {
        title: 'Cerrar Sesión',
        message: '¿Está seguro que desea cerrar sesión?',
        btnConfirm: true,
        btnCancel: true,
        btnConfirmText: 'Aceptar',
        btnCancelText: 'Cancelar'
      }
    })
      .afterClosed()
      .subscribe((confirm: Boolean) => {
        if (confirm) {
          localStorage.clear();
          this.router.navigate(['login']);
        }
      });
  }

  checkUser(): void {
    this._subcriptions['authUser'] = this.authSrv.isUserLogged$.subscribe(
      (isLogged: boolean) => {
        if (isLogged) {
          this.getUser()
        } else {
          this.router.navigate(['login']);
        }
      }
    );
  }

  navigateToUrl(item) {
    this.router.navigate(['/#/' + item.url]);
  }

  preventDefault(e) {
    e.preventDefault();
  }

  getUser(): void {
    this._subcriptions['user'] = this.authSrv.user$.subscribe(
      (user: UserData) => {
        if (user) {
          this.user = user;
          this.menu = user.menu;
          this.menu.forEach((item) => {
            if(item.id === 'CRM') {
              item.items = item.items.filter(subitem => subitem.label !== 'Configuraciones');
            }
          });
        }
      }
    );
  }

  checkNotifications(): void {
    // TODO: Agregar endpoint notificaciones. Usuario?
    this.totalNotifications = 1;
  }

  openFullscreen(): void {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if ((elem as any).webkitRequestFullscreen) { /* Safari */
      (elem as any).webkitRequestFullscreen();
    } else if ((elem as any).msRequestFullscreen) { /* IE11 */
      (elem as any).msRequestFullscreen();
    }
  }

  closeFullscreen(): void {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if ((document as any).webkitExitFullscreen) { /* Safari */
      (document as any).webkitExitFullscreen();
    } else if ((document as any).msExitFullscreen) { /* IE11 */
      (document as any).msExitFullscreen();
    }
  }

  toggleFullscreen(): void {
    if (!this.isFullscreen) {
      this.openFullscreen();
    } else {
      this.closeFullscreen();
    }
  }

  toggleShowSearch(): void {
    this.showSearch = !this.showSearch;
  }

  toggleShowNavBar(event: MouseEvent, flag: null | boolean = null): void {
    console.log('toggleShowNavBar: ', flag, this.isMovil, this.showNavBar)

    if (flag !== null) {
      this.showNavBar = flag;
    } else {
      this.showNavBar = !this.showNavBar;
    }

    if (this.showNavBar && this.showNavPanel) {
      this.showNavPanel = false;
    }
  }

  toggleShowNavPanel(flag: null | boolean = null): void {
    console.log('toggleShowNavPanel: ', flag, this.isMovil, this.showNavPanel)

    if (flag !== null) {
      this.showNavPanel = flag;
    } else {
      this.showNavPanel = !this.showNavPanel;
    }

    if (this.showNavBar && this.showNavPanel) {
      this.showNavBar = false
    }
  }

  isActive(comparisonType: number, url: string): boolean {
    const currentURL = new URL(window.location.href);

    switch (comparisonType) {
      case 1:
        const pathSegments = currentURL.hash.split('/')[1];
        return pathSegments === url;

      case 2:
        const lastPathSegment = currentURL.hash.slice(2);
        return lastPathSegment === url;

      default:
        return false;
    }
  }

  onMouseToggle(flag: boolean, event?: MouseEvent) {
    if ((!flag && ((event && event.clientX >= 260) || !event)) || flag) {
      this.isHovering = flag;
    }
  }

  /* onMouseEnter(e) {
    this.isHovering = true;
    console.log('Mouse entered: ', e);
  }

  onMouseLeave(e) {
    this.isHovering = false;
    console.log('Mouse left: ', e);
  } */

}
