import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailBusinessComponent } from './detail-business.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ToolsModule } from '@tools/tools.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { UploadMainImagenModule } from '@tools/components/upload-main-imagen/upload-main-imagen.module';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ParametersModule } from '@support/pages/parameters/parameters.module';
import { ContractsModule } from '@support/pages/contracts/contracts.module';
import { ChargesModule } from '@support/pages/charges/charges.module';
import { ParkingModule } from '@support/pages/parking/parking.module';
// import { CreatePlanModule } from '@support/pages/payment-plan/create-plan/create-plan.module';
import { PaymentPlanModule } from '@support/pages/payment-plan/payment-plan.module';
import { AdditionalModule } from '@support/pages/additional/additional.module';
import { DiscountsModule } from '@support/pages/discounts/discounts.module';
import { ReservesModule } from '../../reserves/reserves.module';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { DropdownModule } from 'primeng/dropdown';
import { PanelModule } from 'primeng/panel';
import { ChipModule } from 'primeng/chip';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ListboxModule } from 'primeng/listbox';
import { DividerModule } from 'primeng/divider';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { StepsModule } from 'primeng/steps';
import { CardModule } from 'primeng/card';
import { ToolbarModule } from 'primeng/toolbar';
import { TabViewModule } from 'primeng/tabview';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
import { GeneralInfoComponent } from './general-info/general-info.component';
import { ToastModule } from 'primeng/toast';
// import {loadStripe, Stripe} from '@stripe/stripe-js';

import { CreateTransferClientComponent } from './create-transfer-client/create-transfer-client.component';
import { CreateTransferUnitComponent } from './create-transfer-unit/create-transfer-unit.component';
import { ListByTransfersComponent } from './list-by-transfers/list-by-transfers.component';

import { ListByDocumentsComponent } from './list-by-documents/list-by-documents.component';
import { UploadBusinessDocumentComponent } from './upload-business-document/upload-business-document.component';
import {FileUploadModule} from 'primeng/fileupload';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import { CreateChargeComponent } from './create-charge/create-charge.component';
// import {loadStripe, Stripe} from '@stripe/stripe-js';

@NgModule({
  declarations: [
    DetailBusinessComponent,
    GeneralInfoComponent,
    CreateTransferClientComponent,
    CreateTransferUnitComponent,
    ListByTransfersComponent,
    ListByDocumentsComponent,
    UploadBusinessDocumentComponent,
    CreateChargeComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatDividerModule,
    MatGridListModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    ToolsModule,
    MatSnackBarModule,
    MatSelectModule,
    MatCardModule,
    MatCheckboxModule,
    MatRadioModule,
    UploadMainImagenModule,
    UiSwitchModule,
    ParametersModule,
    ContractsModule,
    ParkingModule,
    AdditionalModule,
    DiscountsModule,
    ReservesModule,
    MatTableModule,
    MatChipsModule,
    MatToolbarModule,
    DialogModule,
    ButtonModule,
    TableModule,
    InputTextModule,
    TooltipModule,
    CalendarModule,
    InputNumberModule,
    CurrencyMaskModule,
    DropdownModule,
    PanelModule,
    ChipModule,
    SplitButtonModule,
    ListboxModule,
    DividerModule,
    ConfirmDialogModule,
    StepsModule,
    CardModule,
    ToolbarModule,
    TabViewModule,
    PaymentPlanModule,
    ChargesModule,
    AvatarModule,
    AvatarGroupModule,
    BadgeModule,
    ToastModule,
    FileUploadModule,
    ConfirmPopupModule
    // CreatePlanModule
  ],
  exports: [
    DetailBusinessComponent,
    GeneralInfoComponent,
    CreateTransferClientComponent,
    CreateTransferUnitComponent,
    ListByTransfersComponent,
    ListByDocumentsComponent,
    UploadBusinessDocumentComponent
  ]
})
export class DetailBusinessModule { }
